import $ from 'jquery';

$(document).ready(function () {
    $('a[href^="#"]').on('click', function (event) {
        event.preventDefault();

        var $this = $(this);
        var href = $this.attr('href');
        var pseudoHref = '#!' + href.substring(1);

        window.location.href = '#!';
        window.location.href = pseudoHref;
    });

    function scrollToHref() {
        let href = window.location.href.split('#');

        if (href.length < 2) {
            return;
        }

        let element = document.getElementById(href[1].substring(1));
        let offset = element.offsetTop;
        let navigation = 0;
        if (document.querySelector('.c-header.is-visible')) {
            navigation = document.querySelector('.c-header').clientHeight;
        }
        let scroll = offset - navigation;

        window.scrollTo({
            top: scroll,
            behavior: 'smooth',
        });
    }

    scrollToHref();

    $(window).on('hashchange', scrollToHref);
});

$(document).ready(function () {
    var lang = {
        de: {
            PLEASE_CHOOSE: 'Bitte wählen',
            CHOOSE: 'auswählen',
            CHOOSE_ALL: 'Alle auswählen',
            CHOSEN: 'ausgewählt',
            ALL: 'Alle',
            SEARCH: 'Suche',
            NO_RESULTS_FOR: 'Keine Ergebnisse für ',
            OK: 'OK',
            CANCEL: 'Abbrechen',
        },
        en: {
            PLEASE_CHOOSE: 'Please choose',
            CHOOSE: 'choose',
            CHOOSE_ALL: 'Choose all',
            CHOSEN: 'chosen',
            ALL: 'All',
            SEARCH: 'Search',
            NO_RESULTS_FOR: 'No results for ',
            OK: 'OK',
            CANCEL: 'Cancel',
        },
    };

    lang = lang[$('html').attr('lang').substring(0, 2) || 'de'];

    $('select.js-sumo-select').each(function () {
        var $this = $(this);

        $this.SumoSelect({
            captionFormat: '{0} ' + lang['CHOSEN'],
            captionFormatAllSelected: $this.attr('data-choose-all') || lang['CHOOSE_ALL'],
            floatWidth: 0,
            forceCustomRendering: false,
            noMatch: lang['NO_RESULTS_FOR'] + ' "{0}"',
            placeholder: $this.attr('placeholder') || lang['PLEASE_CHOOSE'],
            search: $this.attr('data-search') || false,
            searchText: lang['SEARCH'],
            selectAll: $this.attr('data-select-all') || false,
            triggerChangeCombined: false,
            locale: [lang['OK'], lang['CANCEL'], $this.attr('data-choose-all') || lang['CHOOSE_ALL']],
            nativeOnDevice: ['Android', 'BlackBerry', 'iPhone', 'iPad', 'iPod', 'Opera Mini', 'IEMobile', 'Silk'],
        });
    });

    /*
     *
     * Select Submit On Change
     *
     * The option value is a key like in normal forms
     *
     */
    $('select.js-submit-onchange').on('change', function () {
        $(this).parents('form').submit();
    });

    /*
     *
     * Select Pageload On Change
     *
     * The option value is a url link
     *
     */
    $('select.js-pageload-onchange').on('change', function () {
        jumpMenu('parent', this, 0);
    });

    /*
     *
     * Disable Submit button after successfully validation
     * and show a spinner icon instead of the button text/icon
     */
    var formSubmitElem = document.querySelector('[data-js-selector="form-submit"]');

    if ($('form[data-validate]').length > 0) {
        $('form[data-validate]')
            .parsley()
            .on('form:validate', function (formInstance) {
                if (formInstance.isValid()) {
                    //
                    formSubmitElem.removeAttribute('type');
                    formSubmitElem.setAttribute('disabled', 'disabled');
                    formSubmitElem.classList.add('c-button--loading');
                }
            });
    }

    $('select.js-sumo-select').on('change', function () {
        if (
            $('#' + $(this).attr('id'))
                .parent()
                .parent()
                .hasClass('parsley-error')
        ) {
            $('form[data-validate]').parsley().validate();
        }
    });
});

//$(window).resize(updateTeaserHeadlineMinHeight());

function jumpMenu(targ, selObj, restore) {
    window.location = '//' + window.location.hostname + selObj.options[selObj.selectedIndex].value;
    if (restore) selObj.selectedIndex = 0;
}
